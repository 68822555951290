import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import axios from 'axios'
import { filter, flatten, forEach, isEmpty } from 'lodash'
import PhoneInput, {
  isPossiblePhoneNumber as validatePhoneNumber,
} from 'react-phone-number-input'

import TickIcon from 'images/icons/tick.svg'
import CloseIcon from 'images/icons/close.svg'
import { rules as signupRules } from 'components/Signup/constants'
import { GA_EVENTS } from 'components/LandingPage/constants'
import endpoints from 'endpoints'
import requestURL from 'utils/requestURL'
import { rules } from './constants'

const { TextArea } = Input

const BookDemoModal = ({ visible, source, toggleVisible, isContactView }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState()
  const isPhoneNumberValid = validatePhoneNumber(phoneNumber || '')

  useEffect(() => {
    if (visible && window && window.gtag && !isContactView) {
      window.gtag('event', GA_EVENTS.BOOK_DEMO_STARTED, {
        source,
      })
    }
  }, [visible, source])

  function onBookingErrors(errors) {
    const newErrors = filter(errors, error => !isEmpty(error))
    const flattenErrors = flatten(newErrors)
    forEach(flattenErrors, err => {
      message.error(err.message ? `${err.field} - ${err.message}` : err)
    })
  }

  function onCloseModal() {
    toggleVisible()
  }

  async function onSubmit(values) {
    setLoading(true)
    await axios
      .post(requestURL(endpoints.requestProductDemo), {
        ...values,
        ...(isContactView && { status: 'contacted' }),
      })
      .then(response => {
        const { data } = response
        if (!isEmpty(data)) {
          setLoading(false)
          onCloseModal()
          message.success(`${isContactView ? 'Contact' : 'Demo'} request sent!`)
          if (window && window.gtag && !isContactView) {
            const {
              first_name: firstName,
              last_name: lastName,
              email,
              company_name: companyName,
            } = values

            window.gtag('event', GA_EVENTS.CONVERSION, {
              send_to: process.env.AW_CONVERSION_ID,
            })
            window.gtag('event', GA_EVENTS.BOOK_DEMO_COMPLETED, {
              user_firstname: firstName,
              user_lastname: lastName,
              user_email: email,
              user_org: companyName,
            })
          }
        }
      })
      .catch(({ response }) => {
        onBookingErrors(response && response.data)
        setLoading(false)
      })
  }

  function validatePhoneNumberRule() {
    return isPhoneNumberValid || isEmpty(phoneNumber)
      ? Promise.resolve()
      : Promise.reject()
  }

  return (
    <Popup
      open={visible}
      closeOnDocumentClick={false}
      modal
      className="request-demo-modal"
    >
      <button type="button" onClick={onCloseModal} className="cancel-button">
        <CloseIcon />
      </button>
      <div className="form-container request-demo-form">
        <Form form={form} onFinish={onSubmit}>
          <div className="form">
            <h2 className="form-heading">
              {isContactView
                ? 'Contact Us'
                : 'Ready to drive incremental sales with LIVE?'}
            </h2>
            <span className="form-sub-heading">
              {!isContactView && (
                <>
                  Request a demo to see how you can leverage LIVE to increase
                  engagement and boost sales. Fill in the form and a member of
                  our team will be in touch.
                </>
              )}
            </span>
            <div className="fieldset-container --grid">
              <div className="fieldset">
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    { max: 255, message: signupRules.maxCharacters },
                    { required: true, message: signupRules.firstName },
                  ]}
                >
                  <Input
                    placeholder="Enter your first name"
                    suffix={<TickIcon className="confirmation-icon" />}
                  />
                </Form.Item>
              </div>
              <div className="fieldset">
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[
                    { max: 255, message: signupRules.maxCharacters },
                    { required: true, message: signupRules.lastName },
                  ]}
                >
                  <Input
                    placeholder="Enter your last name"
                    suffix={<TickIcon className="confirmation-icon" />}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="fieldset-container">
              <div className="fieldset">
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    { type: 'email', message: signupRules.validEmail },
                    { max: 255, message: signupRules.maxCharacters },
                    { required: true, message: signupRules.email },
                  ]}
                >
                  <Input
                    placeholder="Enter your email address"
                    suffix={<TickIcon className="confirmation-icon" />}
                  />
                </Form.Item>
              </div>
            </div>
            {isContactView ? (
              <div className="fieldset-container">
                <div className="fieldset">
                  <Form.Item
                    label="Message"
                    name="message"
                    className="secondary-color"
                    rules={[{ required: true, message: rules.message }]}
                  >
                    <TextArea
                      placeholder="Your message"
                      prefix={<TickIcon className="confirmation-icon" />}
                    />
                  </Form.Item>
                </div>
              </div>
            ) : (
              <>
                <div className="fieldset-container">
                  <div className="fieldset">
                    <Form.Item
                      label="Company"
                      name="company_name"
                      className="secondary-color"
                      rules={[
                        { required: true, message: rules.companyName },
                        { max: 255, message: signupRules.maxCharacters },
                      ]}
                    >
                      <Input
                        placeholder="Enter your company name"
                        prefix={<TickIcon className="confirmation-icon" />}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="fieldset-container">
                  <div className="fieldset">
                    <Form.Item
                      label="Phone Number"
                      name="phone_number"
                      className="secondary-color"
                      rules={[
                        { required: true, message: rules.phoneNumber },
                        {
                          message: rules.invalidPhoneNumber,
                          validator: validatePhoneNumberRule,
                        },
                      ]}
                    >
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        defaultCountry="US"
                      />
                    </Form.Item>
                    {isPhoneNumberValid && (
                      <TickIcon className="confirmation-icon phone-input" />
                    )}
                  </div>
                </div>
              </>
            )}
            <Form.Item noStyle shouldUpdate>
              {({ getFieldsValue }) => {
                const {
                  first_name: firstName,
                  last_name: lastName,
                  email,
                  company_name: companyName,
                  phone_number: phoneNumberValue,
                  message: messageValue,
                } = getFieldsValue() || {}
                const formIsComplete =
                  !!firstName &&
                  !!lastName &&
                  (isContactView
                    ? !!messageValue
                    : !!companyName &&
                      !!email &&
                      !!phoneNumberValue &&
                      isPhoneNumberValid)

                return (
                  <Button
                    loading={loading}
                    disabled={!formIsComplete}
                    className="form-submit-button"
                    htmlType="submit"
                  >
                    {isContactView ? 'Contact Us' : 'REQUEST DEMO'}
                  </Button>
                )
              }}
            </Form.Item>
          </div>
        </Form>
      </div>
    </Popup>
  )
}

BookDemoModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  isContactView: PropTypes.bool,
  source: PropTypes.string,
  toggleVisible: PropTypes.func.isRequired,
}

export default BookDemoModal
