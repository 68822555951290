export const EMAIL_FIELD = 'email'
export const PASSWORD_FIELD = 'password'
export const ORG_SLUG_FIELD = 'org_slug'

export const validationFields = [
  [EMAIL_FIELD, PASSWORD_FIELD],
  null,
  [ORG_SLUG_FIELD],
]
export const DEBOUNCE_TIMER = 1000
export const NO_SPECIAL_CHARACTERS = '[a-zA-Z0-9,-]'
export const WELCOME_ROUTE = '/welcome/'
export const rules = {
  email: 'Email is required',
  validEmail: 'Enter a valid email address',
  password: 'Password is required',
  passwordLength: 'Password must be longer than 6',
  fullName: 'Full Name is required',
  orgnaizationSlug: 'Organization Domain is required',
  maxCharacters: 'Max of 255 characaters',
  noSpecialCharacters: 'No special characters or spaces',
}
export const promoCodeDurationTypes = {
  once: 'once',
  repeating: 'repeating',
  forever: 'forever',
}
