import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { first, isEmpty, map } from 'lodash'
import { useToggle } from 'react-use'

import Divider from 'images/tier-features-divider.svg'
import BookDemoModal from '../BookDemoModal'

const TierItem = ({ tier, previousTierName, onSelect, isLandingPage }) => {
  const [openContactModel, toggleOpenContactModel] = useToggle(false)

  const {
    id: tierId,
    name,
    prices,
    features,
    //  @TODO: Uncomment if best value tag is needed
    // is_best_value: isBestValue,
  } = tier
  const { id: priceId, unit_amount: unitAmount } = first(prices) || {}
  const amount = unitAmount / 100

  function handleClick() {
    onSelect({ product_id: tierId, price_id: priceId })
  }

  function renderTierSubhead() {
    if (priceId) {
      return (
        <>
          <div className="price">
            <span className="currency">$</span>
            <span className="amount">{amount}</span>
          </div>
          <span className="frequency">Per month</span>
        </>
      )
    }

    return isLandingPage ? (
      <Button
        className="contact-us-button"
        htmlType="button"
        onClick={toggleOpenContactModel}
      >
        Contact Us
      </Button>
    ) : (
      <p className="message">Contact us for a price</p>
    )
  }

  return (
    <>
      {/*  @TODO: Uncomment if best value tag is needed
      {isBestValue && <span className="best-value-tag">Best Value</span>} */}
      <div className="tier-header">
        <span className="name">{name}</span>
        {renderTierSubhead()}
      </div>
      <div className="features">
        {!isEmpty(previousTierName) && (
          <>
            {isLandingPage && <span>Everything in {previousTierName}</span>}
            <Divider />
          </>
        )}
        {map(features, feature => (
          <span key={feature}>{feature}</span>
        ))}
      </div>
      {!isLandingPage && (
        <Button
          className="form-submit-button"
          htmlType="button"
          onClick={priceId ? handleClick : toggleOpenContactModel}
        >
          {priceId ? 'Choose this package' : 'Contact Us'}
        </Button>
      )}
      <BookDemoModal
        visible={openContactModel}
        toggleVisible={toggleOpenContactModel}
        isContactView
      />
    </>
  )
}

TierItem.propTypes = {
  tier: PropTypes.object,
  previousTierName: PropTypes.string,
  onSelect: PropTypes.func,
  isLandingPage: PropTypes.bool,
}

export default TierItem
